h9 {
  font-family: "Poppins", sans-serif, "arial";
}



h10 {
  font-family: "Roboto", sans-serif, "arial";
  font-size: 20px;
  color: white;
}

.Contacts {
  font-size: 24px;
}

.Contacts li {
  margin-top: 35px;
}

ul {
  list-style-type: none;
}

.Contacts p {
  display: inline-block;
  padding-left: 30px;
}

@keyframes move-down {
  0% {
    top: 10%;
  }
  50% {
    top: 35%;
  }
  100% {
    top: 50%;
  }
}

/* @media (min-width: 100px) and (max-width: 480px) {

  .flip-person-front, .flip-person-back {
    position: absolute;
    text-align: center;
    backface-visibility: hidden;

  }



  .styled-input {
    width:100%;

}




.flip-person img {
  position: absolute;
  width: 120px;
  height: 120px;

}

.flip-person {

  width: 125px;
  height: 125px;
  border: 3px solid ;
  left: 90%;
  position: relative;
  top: 0%;
}

h9 {
  font-size: 50px;
}

.flip-person-back h1 {

  text-align: center;
  font-size: 14px;
  }

  h10 {

    top: 29%;
    left: 10px;
    position: absolute;


}



  input:focus ~ label, textarea:focus ~ label, input:valid ~ label, textarea:valid ~ label {
    font-size: 0.75em;
    color: #999;
    top: -5px;
    -webkit-transition: all 0.225s ease;
    transition: all 0.225s ease;

  }


  .styled-input {
    width: 400px;
    margin: 1rem 0;
    position: relative;
    border-radius: 4px;
    right: 250px;
  }



  .styled-input label {
    color: #999;
    padding: 1.3rem 30px 1rem 30px;
    position: absolute;
    top: 10px;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    pointer-events: none;

  }

  .styled-input.wide {
    width: 390px;

  }

  .styled-input {
    top: 100px;
    width: 390px;


  }

  input,
  textarea {

    padding: 30px;
    width: 370px;



  }

  input:focus,
  textarea:focus { outline: 0; }



  textarea {
    width: 370px;
    min-height: 20em;
  }

  .input-container {
    width: 2px;
    margin: 10px auto 15px auto;


  }



  .submit-btn {

    cursor: pointer;
    position: absolute;
    top: 1199px;
    left: 270px;
    float: right;
    width:35%;
    text-align:center;
    font-size: 9px;


  }

  .person2 {
    height: 9px;

  }

  .person {
    width: 30px;

  }
} */
