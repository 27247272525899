@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  scroll-behavior: smooth;
  background-color: #6A7EFC;
}

@media (min-width: 401px) {
  body {
    width: 100%;
    height: 100%;
  }

}
