body {
  min-height: 100vh;
  color: white;
}




/* .copywright {
  margin-top: 12px;

} */

/* ._14 {
  margin-top: 22px;
  font-size: 22px;
  font-family: sans-serif;
} */
/* .facebook {
  margin-top: 13px;
}

.instagram {
  margin-top: 13px;
}

.youtube {
  margin-top: 13px;
} */

/* .social-media-icons-white a {
  color: white;
  text-decoration: none;
  border-radius: 100%;
  font-size: 29px;
  font-family: sans-serif;
} */

.facebook a:hover {
  opacity: 1;
  color: rgb(106, 126, 252);
}

.instagram a:hover {
  color: white;
  border-radius: 100%;
  opacity: 1;
  background: #d6249f;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
}

.tiktok a:hover {
  color: #111111;

  filter: drop-shadow(2px 0px 0px #FD3E3E) drop-shadow(-2px -2px 0px #4DE8F4);
}

.youtube a:hover {
  color: white;
  border-radius: 100%;
  opacity: 1;
  background: rgb(255, 86, 86);
}

.footer {
  background-color: rgb(73, 73, 83);
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5%;
}
/*
@media screen and (max-height: 300px) {
  ul {
    margin-top: 40px;
  }
}

@media (min-width: 100px) and (max-width: 480px) {
  .social-media-icons-white a {
    font-size: 9px;
    padding-top: 9px;
  }

  ._14 {

    font-size: 9px;
  }

  .copywright {
    padding-top: 9px;
  }
} */
