


.arrow {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.heading1 {
  left: 20%;
  padding-top: 10%;
  padding-left: 2%;

}






.heading2 {
  right: 42%;
}

.arrow span {
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 5px solid white;
  border-right: 5px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: scroll 2s infinite;
  font-size: 1.2em;
  transition: 0.5s;
  margin-top: 0.45rem;

}
.arrow span:nth-child(2) {
  animation-delay: -.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -.4s;
}

@keyframes scroll {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }

  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
}
