/*





h7 {

  color: whitesmoke;
  font-family: 'Exo 2', sans-serif;
  font-size: 76px;
  font-weight: 900;
  text-transform: uppercase;
  position: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40%;
  left: 35%;



}

p1 {
  max-width: 60%;
  color: whitesmoke;
  font-family: OpenSans;
  font-size: 21px;
  font-weight: 300;
  line-height: 22px;
  position: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 20%;
  top: 60%;
  line-height: 1.6;



}





.About img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  opacity: 0.3;
  height: 100%;



}


@media (min-width: 100px) and (max-width: 480px) {

  .About img {
    max-width: 100%;
    max-height: 100%;
    position: fixed;


  }

  h7 {
    top: 15%;
    left: 20%;
    font-size: 50px;
  }

  p1 {
    top: 25%;
    position: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 1.3;
    font-family: OpenSans;
  }



} */

.aboutrow {

  background-color: rgba( 0, 0, 0, 0.3);
}
