* {
  margin: 0;
  margin: 0;
}

 .main video {
  width: 100%;
  height: 500px;
  object-fit: cover;
  opacity: 0.6;

}

 .main {
  width: 100%;
  height: 100%;
}

@media (min-width: 100px) and (max-width: 480px) {

  .main video {
    width: 100%;
    height: 400px;
    object-fit: cover;
    opacity: 0.6;

  }


}
