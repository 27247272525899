.header {
  background-color: white;
  /* position: absolute;
  /* height: 150px; */
  /* width: 100%; */
  /* top: 0;
  left: 0; */
  /* z-index: 1 */
  /* transition: 0.3s ease-in; */
  overflow: hidden;
}

.header:after {
  content: "";
  display: block;
  height: 4px;
  background: rgb(43, 148, 215);
  background: linear-gradient(
    90deg,
    #233d4d 0%,
    #233d4d 20%,
    #fe7f2d 20%,
    #fe7f2d 40%,
    #fcca46 40%,
    #fcca46 60%,
    #a1c181 60%,
    #a1c181 80%,
    #619b8a 80%,
    #619b8a 100%
  );
}
.menuhidden {
  display: none;
}
/* .header .navbar {
  display: flex;

   justify-content: space-between;
   align-items: center; */
/* max-width: 1240px; */
/* margin: auto; */
/* height: 100%; */
/* padding: 0 1rem; */
/* }

.header .nav-menu a {
  /* color: #090909; */
/* text-transform: uppercase;
  text-decoration: none; */
/* letter-spacing: 0.15em; */
/* display: inline-block;
  padding: 10px 10px; */
/* position: relative; */
/* } */

/* .header .navbar img {
  width: auto;
  height: 100px;

  /* margin-left: 2px; */
/* }  */

/* .header .nav-menu {
  display: flex;
} */

/* .header .nav-item {
  padding: 1rem;
  font-weight: 500;
  list-style: none;
} */

.hamburger {
  display: none;
}

@media screen and (max-width: 940px) {
  /* .header {
    max-width: 100%;
  }

   .header .navbar {
    max-width: 100%;
  } */

  .hamburger {
    display: block;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 90px;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    z-index: 999;
    text-align: center;
    transition: 0.3s;
  }

  .nav-menu.active {
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .nav-item {
    margin: 1.5rem 0;
  }

  .header .navbar img {
    width: 150px;
  }
}

.nav-item {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  font-size: 17px;
  display: inline-block;
  padding: 10px 10px;
  position: relative;
}

.nav-item:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.nav-item:hover:after {
  width: 100%;
  left: 0;
}

/* @media screen and (max-height: 300px) {
  ul {
    margin-top: 40px;
  }
} */
